




import {
  BCard,
  BSkeletonImg,
  BSkeleton,
  BSkeletonWrapper,
} from "bootstrap-vue";
import AvIcon from "@/components/av-icon/AvIcon.vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5locales_pt_BR from "@amcharts/amcharts5/locales/pt_PT";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {
  IPieConfig,
  IPieData,
} from "@/components/av-charts/interfaces/IConfig";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "AvChartPie",
  components: {
    BCard,
    BSkeletonImg,
    BSkeleton,
    BSkeletonWrapper,
    AvIcon,
  },
})
export default class AvChartPie extends Vue {
  @Prop({ required: true }) data!: IPieData[];
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) config!: IPieConfig;

  series: any = null;
  root: am5.Root | null = null;

  // Watchs
  @Watch("data", { immediate: true })
  refreashChart() {
    this.root?.dispose();
    if (document.getElementById(this.id)) am5.ready(this.am5Read);
  }

  mounted() {
    this.refreashChart();
  }

  // Functions
  am5Read() {
    this.series = null;
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    this.root = this.setUpRoot();

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = this.setUpChart(this.root);

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = this.setUpSeries(this.root, chart);

    this.setUpLegenda(this.root, chart, series);

    this.$emit("root", this.root);
  }

  setUpRoot() {
    let root: am5.Root = am5.Root.new(this.id);

    root.locale = am5locales_pt_BR;

    root.setThemes([am5themes_Animated.new(root)]);

    return root;
  }

  setUpChart(root: am5.Root) {
    return root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
      })
    );
  }

  setUpSeries(root: am5.Root, chart: am5percent.PieChart) {
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        endAngle: 270,
        name: "Series",
        alignLabels: false,
      })
    );

    series.states.create("hidden", {
      endAngle: -90,
    });

    series.slices.template.setAll({
      templateField: "columnSettings",
    });

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(this.data);

    series.labels.template.setAll(
      this.config.labels || {
        text: "{category}",
        textType: "circular",
        inside: true,
        radius: 10,
        fill: am5.color(0xffffff),
      }
    );

    series.appear(1000, 100);

    return series;
  }

  setUpLegenda(
    root: am5.Root,
    chart: am5percent.PieChart,
    series: { dataItems: unknown[] }
  ) {
    let legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: "category",
        centerX: am5.percent(50),
        centerY: am5.percent(10),
        x: am5.percent(50),
      })
    );

    legend.data.setAll(series.dataItems);

    return legend;
  }
}
