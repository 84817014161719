import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default function useExport(ctx: any) {
  /**
   * Essa função gera o arquivo de exportação PDF com base na documentação do amChart 5;
   * Ref: https://codepen.io/team/amcharts/pen/yLPLNYB?editors=1010
   */
  const exportPdf = (): void => {
    const totalMarketShareCurrentMonthExporting =
      am5plugins_exporting.Exporting.new(
        ctx.totalMarketShareCurrentMonth.root,
        {
          menu: am5plugins_exporting.ExportingMenu.new(
            ctx.totalMarketShareCurrentMonth.root,
            {}
          ),
        }
      );
    const pie1DataExporting = am5plugins_exporting.Exporting.new(
      ctx.pie1Data.root,
      {
        menu: am5plugins_exporting.ExportingMenu.new(ctx.pie1Data.root, {}),
      }
    );
    const totalMarketShareYearToDateExporting =
      am5plugins_exporting.Exporting.new(ctx.totalMarketShareYearToDate.root, {
        menu: am5plugins_exporting.ExportingMenu.new(
          ctx.totalMarketShareYearToDate.root,
          {}
        ),
      });
    const pie2DataExporting = am5plugins_exporting.Exporting.new(
      ctx.pie2Data.root,
      {
        menu: am5plugins_exporting.ExportingMenu.new(ctx.pie2Data.root, {}),
      }
    );
    const brandEvolutionDataExporting = am5plugins_exporting.Exporting.new(
      ctx.brandEvolutionData.root,
      {
        menu: am5plugins_exporting.ExportingMenu.new(
          ctx.brandEvolutionData.root,
          {}
        ),
      }
    );
    const dailyBrandEvolutionDataExporting = am5plugins_exporting.Exporting.new(
      ctx.dailyBrandEvolutionData.root,
      {
        menu: am5plugins_exporting.ExportingMenu.new(
          ctx.dailyBrandEvolutionData.root,
          {}
        ),
      }
    );
    const largestEconomicGroupsYearDateExporting =
      am5plugins_exporting.Exporting.new(
        ctx.largestEconomicGroupsYearDate.root,
        {
          menu: am5plugins_exporting.ExportingMenu.new(
            ctx.largestEconomicGroupsYearDate.root,
            {}
          ),
        }
      );
    const growthByBrandExporting = am5plugins_exporting.Exporting.new(
      ctx.growthByBrand.root,
      {
        menu: am5plugins_exporting.ExportingMenu.new(
          ctx.growthByBrand.root,
          {}
        ),
      }
    );

    Promise.all([
      totalMarketShareCurrentMonthExporting.getPdfmake(),
      totalMarketShareCurrentMonthExporting.export("png"),
      pie1DataExporting.export("png"),
      totalMarketShareYearToDateExporting.export("png"),
      pie2DataExporting.export("png"),
      brandEvolutionDataExporting.export("png"),
      dailyBrandEvolutionDataExporting.export("png"),
      largestEconomicGroupsYearDateExporting.export("png"),
      growthByBrandExporting.export("png"),
    ])
      .then((res) => {
        let pdfMake = res[0];
        const title1: string =
          document.querySelector(
            "#chart-total-market-share-current-month-print .title"
          )?.textContent || "";
        const title2: string =
          document.querySelector(
            "#chart-venda-direta-vs-venda-varejo-fabricante-print .title"
          )?.textContent || "";
        const title3: string =
          document.querySelector(
            "#chart-total-market-share-year-to-date-print .title"
          )?.textContent || "";
        const title4: string =
          document.querySelector(
            "#chart-venda-direta-vs-venda-varejo-ano-print .title"
          )?.textContent || "";
        const title5: string =
          document.querySelector("#chart-brand-evolution-print .title")
            ?.textContent || "";
        const title6: string =
          document.querySelector("#chart-daily-brand-evolution-print .title")
            ?.textContent || "";
        const title7: string =
          document.querySelector(
            "#chart-largest-economic-groups-year-date-print .title"
          )?.textContent || "";
        const title8: string =
          document.querySelector("#chart-growth-by-brand-print .title")
            ?.textContent || "";

        // pdfmake is ready
        // Create document template
        let doc = {
          pageSize: "A4",
          pageOrientation: "portrait",
          pageMargins: [30, 30, 30, 30],
          content: [] as Array<any>,
        };

        doc.content.push({
          columns: [
            {
              text: title1,
              fontSize: 10,
              bold: true,
              width: 350,
              margin: [0, 20, 0, 15],
            },
            {
              text: title2,
              fontSize: 10,
              bold: true,
              width: 150,
              margin: [0, 20, 0, 15],
            },
          ],
          columnGap: 30,
        });

        doc.content.push({
          columns: [
            {
              image: res[1],
              width: 350,
            },
            {
              image: res[2],
              width: 150,
            },
          ],
          columnGap: 30,
        });

        doc.content.push({
          columns: [
            {
              text: title3,
              fontSize: 10,
              bold: true,
              width: 350,
              margin: [0, 20, 0, 15],
            },
            {
              text: title4,
              fontSize: 10,
              bold: true,
              width: 150,
              margin: [0, 20, 0, 15],
            },
          ],
          columnGap: 30,
        });

        doc.content.push({
          columns: [
            {
              image: res[3],
              width: 350,
            },
            {
              image: res[4],
              width: 150,
            },
          ],
          columnGap: 30,
        });

        doc.content.push({
          text: title5,
          fontSize: 10,
          bold: true,
          margin: [0, 20, 0, 15],
        });

        doc.content.push({
          image: res[5],
          width: 530,
        });

        doc.content.push({
          text: title6,
          fontSize: 10,
          bold: true,
          margin: [0, 20, 0, 15],
        });

        doc.content.push({
          image: res[6],
          width: 530,
        });

        doc.content.push({
          columns: [
            {
              text: title7,
              fontSize: 10,
              bold: true,
              width: 250,
              margin: [0, 20, 0, 15],
            },
            {
              text: title8,
              fontSize: 10,
              bold: true,
              width: 250,
              margin: [0, 20, 0, 15],
            },
          ],
          columnGap: 30,
        });

        doc.content.push({
          columns: [
            {
              image: res[7],
              width: 250,
            },
            {
              image: res[8],
              width: 250,
            },
          ],
          columnGap: 30,
        });

        pdfMake.createPdf(doc).download("DashboardKPI.pdf");
      })
      .catch(() => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Ocorreu um erro inesperado ao tentar gerar arquivo!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    exportPdf,
  };
}
