




import {
  BCard,
  BSkeletonImg,
  BSkeleton,
  BSkeletonWrapper,
} from "bootstrap-vue";
import AvIcon from "@/components/av-icon/AvIcon.vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_pt_BR from "@amcharts/amcharts5/locales/pt_PT";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import {
  IRangeConfig,
  IRangeData,
  IRangeSeriesConfig,
} from "@/components/av-charts/interfaces/IConfig";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import moment from "moment";

@Component({
  name: "AvChartRange",
  components: {
    BCard,
    BSkeletonImg,
    BSkeleton,
    BSkeletonWrapper,
    AvIcon,
  },
})
export default class AvChartRange extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) data!: Array<IRangeData>;
  @Prop({ required: true }) date!: string;
  @Prop({ required: true }) config!: IRangeConfig;

  series: any = null;
  root: am5.Root | null = null;
  axis: { xAxis: any; yAxis: any } | null = null;

  // Watchs
  @Watch("data", { immediate: true })
  refreashChart() {
    this.root?.dispose();
    if (document.getElementById(this.id)) am5.ready(this.am5Read);
  }

  mounted() {
    this.refreashChart();
  }

  am5Read() {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    this.root = this.configRoot();

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = this.setUpChart(this.root);

    this.setUpCursor(this.root, chart);

    this.axis = this.setUpAxis(this.root, chart, this.config.timeUnit);

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series: am5xy.LineSeries[] = [];
    for (let index = 0; index < this.config.series.length; index++) {
      let item = this.setUpSeries(this.root, chart, this.config.series[index]);
      series.push(item);
      if (this.config.series[index].onBullet) {
        this.configBullet(item, this.config.series[index]);
      }
    }

    let sbseries = this.setUpSrollBars(this.root, chart);

    sbseries.data.setAll(this.data);

    for (let index = 0; index < series.length; index++) {
      series[index].data.setAll(this.data);
    }

    this.configRanges(series);

    this.configLegends(this.root, chart, this.config);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    for (let index = 0; index < series.length; index++) {
      series[index].appear(1000);
    }

    chart.appear(1000, 100);

    this.configInitialZoom(series, this.config.initialZoom);
    
    this.$emit("root", this.root);
  }

  configRoot() {
    let root = am5.Root.new(this.id);

    root.locale = am5locales_pt_BR;
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    let responsive = am5themes_Responsive.newEmpty(root);

    const applying = () => {
      let lastDate = moment(this.date);

      this.axis?.xAxis.zoomToDates(
        moment(lastDate).add(-3, "M").toDate(),
        lastDate.toDate()
      );
    };

    const removing = () => {
      let lastDate = moment(this.date);
      this.axis?.xAxis.zoomToDates(
        moment(lastDate).add(-6, "M").toDate(),
        lastDate.toDate()
      );
    };

    responsive.addRule({
      name: "AxisRendererY",
      relevant: am5themes_Responsive.widthL,
      applying: applying,
      removing: removing,
    });

    root.setThemes([am5themes_Animated.new(root), responsive]);

    return root;
  }

  setUpChart(root: am5.Root) {
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      })
    );

    chart.get("colors")?.set("step", 5);

    return chart;
  }

  setUpCursor(root: am5.Root, chart: am5xy.XYChart) {
    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );

    cursor.lineY.set("visible", false);
  }

  setUpAxis(
    root: am5.Root,
    chart: am5xy.XYChart,
    timeUnit:
      | "day"
      | "hour"
      | "millisecond"
      | "minute"
      | "month"
      | "second"
      | "week"
      | "year"
  ) {
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: timeUnit, count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 40,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    return {
      xAxis,
      yAxis,
    };
  }

  setUpSeries(
    root: am5.Root,
    chart: am5xy.XYChart,
    seriesConfig: IRangeSeriesConfig
  ): am5xy.LineSeries {
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series: am5xy.LineSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: seriesConfig.name,
        valueYField: seriesConfig.valueYField,
        openValueYField: seriesConfig.openValueYField || undefined,
        valueXField: seriesConfig.valueXField,
        stroke: am5.color(seriesConfig.stroke),
        fill: am5.color(seriesConfig.fill),
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}",
        }),
        xAxis: this.axis?.xAxis,
        yAxis: this.axis?.yAxis,
      })
    );

    series.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["date"],
      dateFormat: "yyyy-MM-dd",
    });

    if (seriesConfig.template) {
      series.fills.template.setAll(seriesConfig.template);
    }

    return series;
  }

  setUpSrollBars(root: am5.Root, chart: am5xy.XYChart) {
    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    let scrollbarX = am5xy.XYChartScrollbar.new(root, {
      orientation: "horizontal",
    });

    chart.set("scrollbarX", scrollbarX);

    let sbxAxis = scrollbarX.chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        groupData: true,
        groupIntervals: [{ timeUnit: "month", count: 1 }],
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          opposite: false,
          strokeOpacity: 0,
        }),
      })
    );

    let sbyAxis = scrollbarX.chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    let sbseries = scrollbarX.chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: sbxAxis,
        yAxis: sbyAxis,
        valueYField: "value",
        valueXField: "date",
      })
    );

    return sbseries;
  }

  configBullet(series: am5xy.LineSeries, seriesConfig: IRangeSeriesConfig) {
    series.bullets.push(function (root: am5.Root) {
      return am5.Bullet.new(root, {
        locationX: seriesConfig.bullet?.locationX,
        locationY: seriesConfig.bullet?.locationY,
        sprite: am5.Circle.new(root, {
          radius: seriesConfig.bullet?.sprite.radius || 5,
          fill: series.get("fill"),
          draw: seriesConfig.bullet?.sprite.draw,
          stroke: root.interfaceColors.get("background"),
          strokeWidth: seriesConfig.bullet?.sprite.strokeWidth || 2,
          tooltipText: seriesConfig.bullet?.sprite.tooltipText,
          showTooltipOn: seriesConfig.bullet?.sprite.showTooltipOn,
          tooltip: am5.Tooltip.new(root, {
            labelText: seriesConfig.bullet?.sprite.tooltip.labelText,
          }),
        }),
      });
    });
  }

  configRanges(series: am5xy.LineSeries[]) {
    type TPoint = {
      x: number;
      y: number;
    };

    let getLineIntersection = (
      pointA1: TPoint,
      pointA2: TPoint,
      pointB1: TPoint,
      pointB2: TPoint
    ): TPoint => {
      let x =
        ((pointA1.x * pointA2.y - pointA2.x * pointA1.y) *
          (pointB1.x - pointB2.x) -
          (pointA1.x - pointA2.x) *
            (pointB1.x * pointB2.y - pointB1.y * pointB2.x)) /
        ((pointA1.x - pointA2.x) * (pointB1.y - pointB2.y) -
          (pointA1.y - pointA2.y) * (pointB1.x - pointB2.x));
      let y =
        ((pointA1.x * pointA2.y - pointA2.x * pointA1.y) *
          (pointB1.y - pointB2.y) -
          (pointA1.y - pointA2.y) *
            (pointB1.x * pointB2.y - pointB1.y * pointB2.x)) /
        ((pointA1.x - pointA2.x) * (pointB1.y - pointB2.y) -
          (pointA1.y - pointA2.y) * (pointB1.x - pointB2.x));

      return { x: x, y: y };
    };

    if (this.axis) {
      // create ranges
      let i = 0;
      var baseInterval = this.axis?.xAxis.get("baseInterval");
      var baseDuration = this.axis?.xAxis.baseDuration();
      var rangeDataItem: any;

      const interateEach = (s1DataItem: any) => {
        var s1PreviousDataItem: any;
        var s2PreviousDataItem: any;

        var s2DataItem: any = series[1].dataItems[i];

        if (i > 0) {
          s1PreviousDataItem = series[0].dataItems[i - 1];
          s2PreviousDataItem = series[1].dataItems[i - 1];
        }

        var startTime = am5.time
          .round(
            new Date(s1DataItem.get("valueX")),
            baseInterval.timeUnit,
            baseInterval.count
          )
          .getTime();

        // intersections
        if (s1PreviousDataItem && s2PreviousDataItem) {
          var x0 =
            am5.time
              .round(
                new Date(s1PreviousDataItem.get("valueX") || ""),
                baseInterval.timeUnit,
                baseInterval.count
              )
              .getTime() +
            baseDuration / 2;
          var y01 = s1PreviousDataItem.get("valueY");
          var y02 = s2PreviousDataItem.get("valueY");

          var x1 = startTime + baseDuration / 2;
          var y11 = s1DataItem.get("valueY");
          var y12 = s2DataItem.get("valueY");

          var intersection = getLineIntersection(
            { x: x0, y: y01 },
            { x: x1, y: y11 },
            { x: x0, y: y02 },
            { x: x1, y: y12 }
          );

          startTime = Math.round(intersection.x);
        }

        // start range here
        if (s2DataItem.get("valueY") > s1DataItem.get("valueY")) {
          if (!rangeDataItem) {
            rangeDataItem = this.axis?.xAxis.makeDataItem({});
            let range: any = series[0].createAxisRange(rangeDataItem);

            rangeDataItem.set("value", startTime);
            range.fills.template.setAll({
              fill: series[1].get("fill"),
              fillOpacity: 0.6,
              visible: true,
            });

            range.strokes.template.setAll({
              stroke: series[0].get("stroke"),
              strokeWidth: 1,
            });
          }
        } else {
          // if negative range started
          if (rangeDataItem) {
            rangeDataItem.set("endValue", startTime);
          }

          rangeDataItem = undefined;
        }
        // end if last
        if (i == series[0].dataItems.length - 1) {
          if (rangeDataItem) {
            rangeDataItem.set(
              "endValue",
              s1DataItem.get("valueX") + baseDuration / 2
            );
            rangeDataItem = undefined;
          }
        }

        i++;
      };

      am5.array.each(series[0].dataItems, interateEach);
    }
  }

  configLegends(root: am5.Root, chart: am5xy.XYChart, config: IRangeConfig) {
    //Adicionando legenda
    var legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: "name",
        fillField: "color",
        strokeField: "color",
        layout: root.horizontalLayout,
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        x: am5.percent(50),
      })
    );

    legend.data.setAll(
      config.series.map((series) => ({
        name: series.valueYField,
        color: am5.color(series.fill),
      }))
    );

    return legend;
  }

  configInitialZoom(
    series: am5xy.LineSeries[],
    initialZoom?: { xs?: number; sm?: number; md?: number; lg?: number }
  ) {
    const zoomToDates = () => {
      let lastDate = moment(this.date);
      // Baseado nos breakpoints do bootstrap
      if (window.innerWidth < 576) {
        this.axis?.xAxis.zoomToDates(
          moment(lastDate)
            .add(initialZoom?.xs || -3, "M")
            .toDate(),
          lastDate.toDate()
        );
      } else if (window.innerWidth < 768) {
        this.axis?.xAxis.zoomToDates(
          moment(lastDate)
            .add(initialZoom?.sm || -6, "M")
            .toDate(),
          lastDate.toDate()
        );
      } else if (window.innerWidth < 992) {
        this.axis?.xAxis.zoomToDates(
          moment(lastDate)
            .add(initialZoom?.md || -8, "M")
            .toDate(),
          lastDate.toDate()
        );
      } else {
        this.axis?.xAxis.zoomToDates(
          moment(lastDate)
            .add(initialZoom?.lg || -12, "M")
            .toDate(),
          lastDate.toDate()
        );
      }
    };

    for (let index = 0; index < series.length; index++) {
      series[index].events.once("datavalidated", zoomToDates);
    }
  }
}
